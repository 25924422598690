import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import gnIcon from "../../images/about/gn-icon.svg";
import ContactUs from "../../components/ContactUs";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import RaimundoRoberts from "../../images/about/gn-members/Raimundo_Roberts_Photo.jpg";
import DavidWinickoff from "../../images/about/gn-members/David_Winickoff_Photo.jpg";
import IngeborgMeijer from "../../images/about/gn-members/Ingeborg_Meijer_Photo.jpg";
import NeliusBoshoff from "../../images/about/gn-members/Nelius_Boshoff_Photo.jpg";
import ThomasWoodson from "../../images/about/gn-members/Thomas_Woodson_Photo.jpg";
import JulianaChaves from "../../images/about/gn-members/Juliana_Chaves_Photo.svg";
import MasaruYarime from "../../images/about/gn-members/Masaru_Yarime_Photo.png";
import JuliaTaguena from "../../images/about/gn-members/Julia_Taguena_Photo.JPG";
import MichaelEllis from "../../images/about/gn-members/Michael_Ellis_Photo.jpg";
import ErikFisher from "../../images/about/gn-members/Erik_Fisher_Photo.jpg";
import BerndStahl from "../../images/about/gn-members/Bernd_Stahl_Photo.jpg";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./AboutGlobalNetworkPage.css";

export default function AboutGlobalNetworkPage() {
    return (
        <div className="page-container">
            <Header className="header-consortium" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">About</div>
                    <div className="title-title">Global Network</div>
                </div>
            </div>
            <div className="gn-members">
                <div className="gn-icon">
                    <img src={gnIcon} alt="gnIcon" />
                </div>
                <div className="gn-breadcrumb">
                    <div className="content">
                        Home / About / Global Network
                    </div>
                </div>
                <div className="gn-introduction">
                    <div className="content">
                        <div className="body">
                            The Global Network of ORRI Practitioners, aims to strengthen knowledge
                            and collaboration on responsible innovation across different sectors in
                            Europe and beyond. Its main objective is to support organizations and
                            institutions to transition to a new paradigm where responsibility and
                            openness drive research and innovation processes.
                        </div>
                        <div className="footer">
                            Get to know the members of our Network!
                        </div>
                    </div>
                </div>
                <div className="gn-members-carousel">
                    <div className="carousel carousel-dark slide" id="carouselControls" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="carousel-card">
                                    <div className="carousel-img">
                                        <img src={BerndStahl} className="d-block w-50" alt="Bernd Stahl" />
                                    </div>
                                    <div className="carousel-info">
                                        <div className="carousel-member-name">
                                            <a href="https://www.linkedin.com/in/bernd-carsten-stahl-9879105/?originalSubdomain=uk" target="_blank" rel="noreferrer" className="btn-linkedIn">
                                                <span className="linkedIn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 15 20">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <h3>Bernd Stahl</h3>
                                        </div>
                                        <p className="carousel-member-body">
                                            Bernd Carsten Stahl is Professor of Critical Research in Technology at
                                            the School of Computer Science of the University of Nottingham. His
                                            interests cover philosophical issues arising from the intersections of
                                            business, technology, and information. This includes ethical questions
                                            of current and emerging of ICTs, critical approaches to information 
                                            systems and issues related to responsible research and innovation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="carousel-card">
                                    <div className="carousel-img">
                                        <img src={DavidWinickoff} className="d-block w-50" alt="David Winickoff" />
                                    </div>
                                    <div className="carousel-info">
                                        <div className="carousel-member-name">
                                            <a href="https://www.linkedin.com/in/david-winickoff-b9762271/?originalSubdomain=fr" target="_blank" rel="noreferrer" className="btn-linkedIn">
                                                <span className="linkedIn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 15 20">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <h3>David Winickoff</h3>
                                        </div>
                                        <p className="carousel-member-body">
                                            David Winickoff is the Head of the Responsible Innovation Unit in
                                            the Division of Science and Technology Policy at the Organisation
                                            for Economic Cooperation and Development (OECD) in Paris. He is
                                            also the Secretary of the Working Party on Bio-, Nano- and Converging
                                            Technology and co-lead on the Global Forum on Technology. In these
                                            capacities he oversees research, policy work, and soft law development
                                            in the area of science, technology, and innovation at the national and
                                            international levels. He has over 60 publications and teaches at
                                            SciencesPo Law School. He is a graduate of Harvard, Yale, and Cambridge,
                                            and has served on expert panels for various global institutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="carousel-card">
                                    <div className="carousel-img">
                                        <img src={ErikFisher} className="d-block w-50" alt="Erik Fisher" />
                                    </div>
                                    <div className="carousel-info">
                                        <div className="carousel-member-name">
                                            <a href="https://www.linkedin.com/in/fishererik/" target="_blank" rel="noreferrer" className="btn-linkedIn">
                                                <span className="linkedIn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 15 20">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <h3>Erik Fisher</h3>
                                        </div>
                                        <p className="carousel-member-body">
                                            Erik Fisher is an associate professor at the School for the Future
                                            of Innovation in Society at Arizona State University, where he
                                            directs the Center for Responsible Innovation and serves as Faculty
                                            Chair for the Public Interest Technology MS program. Since 2016,
                                            Fisher has served editor-in-chief of the Journal of Responsible
                                            Innovation. Since the early 2000s, he and his students and
                                            collaborators have developed and deployed Socio-Technical Integration
                                            Research (STIR) in over 80 organizations on three continents.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="carousel-card">
                                    <div className="carousel-img">
                                        <img src={IngeborgMeijer} className="d-block w-50" alt="Ingeborg Meijer" />
                                    </div>
                                    <div className="carousel-info">
                                        <div className="carousel-member-name">
                                            <a href="https://www.linkedin.com/in/ingeborg-meijer-915551a/?originalSubdomain=nl" target="_blank" rel="noreferrer" className="btn-linkedIn">
                                                <span className="linkedIn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 15 20">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <h3>Ingeborg Meijer</h3>
                                        </div>
                                        <p className="carousel-member-body">
                                            Dr Ingeborg Meijer is a scientist and policy officer working at the
                                            interface of Open and Responsible R&I practices at the Center of
                                            Science and Technology Studies (CWTS) of Leiden University and science
                                            policy in the Ministry of Education, Culture and Science of the Netherlands. 
                                        </p>
                                        <p className="carousel-member-body">
                                            In the last ten years she worked on five European RRI projects: MoRRI,
                                            NewHoRRIzon, SUPER_MoRRI, CHERRIES and RIPEET experimenting with grounding
                                            practices and monitoring & evaluation of RRI. By working in science and
                                            policy, her interest is now focused at the Science for Policy activities,
                                            both European, and local.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="carousel-card">
                                    <div className="carousel-img">
                                        <img src={JuliaTaguena} className="d-block w-50" alt="Julia Taguena" />
                                    </div>
                                    <div className="carousel-info">
                                        <div className="carousel-member-name">
                                            <a href="https://www.linkedin.com/in/julia-tag%C3%BCe%C3%B1a-31115113/?trk=public_profile_browsemap_profile-result-card_result-card_full-click&originalSubdomain=mx" target="_blank" rel="noreferrer" className="btn-linkedIn">
                                                <span className="linkedIn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 15 20">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <h3>Julia Tagüeña</h3>
                                        </div>
                                        <p className="carousel-member-body">
                                            Julia Tagüeña is a senior researcher at the Institute of Renewable
                                            Energies of the Universidad Nacional Autónoma de México (UNAM).
                                            She studied physics at UNAM and obtained a PhD at Oxford University.
                                            Her main fields of research are solid-state physics, renewable
                                            energies and science communication. She is a member of the Mexico's
                                            National Research System, with the highest rank, and of different
                                            societies such the Mexican Academy of Sciences, the Institute of
                                            Physics of UK and the International Women Forum, Mexican chapter.
                                            She was the Deputy Director General for Scientific Development at
                                            the Mexican National Council of Science and Technology, 2013-2018.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="carousel-card">
                                    <div className="carousel-img">
                                        <img src={JulianaChaves} className="d-block w-50" alt="Juliana Chaves" />
                                    </div>
                                    <div className="carousel-info">
                                        <div className="carousel-member-name">
                                            <a href="https://www.linkedin.com/in/juliana-chaves-chaparro-576b6213/?locale=en_US" target="_blank" rel="noreferrer" className="btn-linkedIn">
                                                <span className="linkedIn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 15 20">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <h3>Juliana Chaves</h3>
                                        </div>
                                        <p className="carousel-member-body">
                                            Juliana Chaves, VERITY advisory board member, is senior consultant at
                                            UNESCO MOST Section in charge of the Horizon Europe Open Universal
                                            Science (OPUS) project. With more than 20 years of international working
                                            experience in the promotion of the science-policy-society interface in
                                            various regions, and particularly in the Global South. She has been
                                            coordinator, co-editor and co-author of the "Co-designing science in
                                            Africa" UNESCO publication, among others. She is the current President
                                            of the NGO Mixtura ambiental and VERITY advisory board member.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="carousel-card">
                                    <div className="carousel-img">
                                        <img src={MasaruYarime} className="d-block w-50" alt="Masaru Yarime" />
                                    </div>
                                    <div className="carousel-info">
                                        <div className="carousel-member-name">
                                            <a href="https://www.linkedin.com/in/yarimemasaru/" target="_blank" rel="noreferrer" className="btn-linkedIn">
                                                <span className="linkedIn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 15 20">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <h3>Masaru Yarime</h3>
                                        </div>
                                        <p className="carousel-member-body">
                                            Masaru Yarime is an Associate Professor at the Division of Public
                                            Policy and the Division of Environment and Sustainability of the
                                            Hong Kong University of Science and Technology. He also has
                                            appointments as a Visiting Associate Professor at the Graduate School
                                            of Public Policy at the University of Tokyo and an Honorary Associate
                                            Professor at the Department of Science, Technology, Engineering, and
                                            Public Policy at University College London. His research interest
                                            centers around science, technology, and innovation policy for energy,
                                            environment, and sustainability. He is currently exploring data-driven
                                            innovation, including AI, the IoT, blockchain, and smart cities, to
                                            address sustainability challenges and implications for public policy
                                            and governance. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="carousel-card">
                                    <div className="carousel-img">
                                        <img src={MichaelEllis} className="d-block w-50" alt="Michael Ellis" />
                                    </div>
                                    <div className="carousel-info">
                                        <div className="carousel-member-name">
                                            <a href="https://www.linkedin.com/in/michael-b-ellis/?originalSubdomain=za" target="_blank" rel="noreferrer" className="btn-linkedIn">
                                                <span className="linkedIn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 15 20">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <h3>Michael Ellis</h3>
                                        </div>
                                        <p className="carousel-member-body">
                                            Michael Ellis is a science communication manager at the South African
                                            Agency for Science and Technology Advancement (SAASTA), a business unit
                                            of the National Research Foundation (NRF). He currently managers the
                                            science communication division and is responsible for coordinating
                                            various science communication programmes in South Africa. Michael has
                                            served on the management teams of the Cape Town Science Centre and
                                            Sci-Bono Discovery Centre in Johannesburg. He oversees the community-based
                                            participatory research grant programme, a science journalism internship
                                            programme and various other science engagement programmes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="carousel-card">
                                    <div className="carousel-img">
                                        <img src={NeliusBoshoff} className="d-block w-50" alt="Nelius Boshoff" />
                                    </div>
                                    <div className="carousel-info">
                                        <div className="carousel-member-name">
                                            <a href="https://www.linkedin.com/in/nelius-boshoff-585591320/" target="_blank" rel="noreferrer" className="btn-linkedIn">
                                                <span className="linkedIn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 15 20">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <h3>Nelius Boshoff</h3>
                                        </div>
                                        <p className="carousel-member-body">
                                            Nelius Boshoff is an Associate Professor at the Centre for Research
                                            on Evaluation, Science and Technology (CREST) at Stellenbosch University,
                                            South Africa. He specialises in research uptake, collaboration, bibliometric
                                            analysis, and the societal impact of research, with a focus on Africa.
                                            Holding a PhD in Science and Technology Studies and a Master’s in Research
                                            Psychology (Cum Laude), his work includes bibliometric studies on inequality
                                            in forest science and the societal value of research. Nelius has authored
                                            numerous peer-reviewed articles and over 100 research reports. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="carousel-card">
                                    <div className="carousel-img">
                                        <img src={RaimundoRoberts} className="d-block w-50" alt="Raimundo Roberts" />
                                    </div>
                                    <div className="carousel-info">
                                        <div className="carousel-member-name">
                                            <a href="https://www.linkedin.com/in/raimundo-roberts-molina/?originalSubdomain=cl" target="_blank" rel="noreferrer" className="btn-linkedIn">
                                                <span className="linkedIn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 15 20">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <h3>Raimundo Roberts</h3>
                                        </div>
                                        <p className="carousel-member-body">
                                            Raimundo Roberts is a science journalist, parliamentary technical advisor
                                            and researcher on science and technology issues at the Parliamentary
                                            Technical Advisory Service of the Library of the National Congress of
                                            Chile, where he works to strengthen the use of scientific evidence in
                                            legislative technical advisory processes. He holds a master in scientific
                                            communication from the Pompeu Fabra University, a Diploma in Bioethics and
                                            Quality of Life from the University of Barcelona and a Diploma in Law and
                                            Artificial Intelligence from the Catholic University of Chile. Former
                                            president and vice-president of the Chilean Association of Journalists and
                                            Professionals of Scientific Communication, creator and responsible for
                                            scientific dissemination programmes and professor of scientific journalism.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="carousel-card">
                                    <div className="carousel-img">
                                        <img src={ThomasWoodson} className="d-block w-50" alt="Thomas Woodson" />
                                    </div>
                                    <div className="carousel-info">
                                        <div className="carousel-member-name">
                                            <a href="https://www.linkedin.com/in/thomas-woodson-11a81289/" target="_blank" rel="noreferrer" className="btn-linkedIn">
                                                <span className="linkedIn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 15 20">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <h3>Thomas Woodson</h3>
                                        </div>
                                        <p className="carousel-member-body">
                                            Dr. Thomas Woodson is an associate professor in the Department of
                                            Technology and Society at Stony Brook University, researches technology's
                                            global impacts on inequality and inclusive innovation. His current work
                                            examines the effectiveness of scientific funding in promoting societal
                                            benefits and fostering diversity in STEM. He serves as campus director
                                            for SBU's Louis Stokes Alliance for Minority Participation (SUNY LSAMP)
                                            and was Principal Investigator for the $1 million NSF-funded S-STEM ASSETS
                                            program supporting STEM transfer students. Dr. Woodson earned a B.S.E. in
                                            electrical engineering from Princeton University and a Ph.D. in Public
                                            Policy from Georgia Tech, focusing on initiatives that enhance diversity
                                            and equity in science and technology fields.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}