import { NotificationContainer, NotificationManager } from 'react-notifications';
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import { yupResolver } from '@hookform/resolvers/yup';
import Newsletter from "../../components/Newsletter";
import ContactUs from "../../components/ContactUs";
import React, { useState, useEffect } from "react";
import { Form, Button } from 'react-bootstrap';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useForm } from "react-hook-form";
import { FaTrash } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import * as yup from "yup";

import "react-datepicker/dist/react-datepicker.css";
import "react-notifications/lib/notifications.css";
import "./OpenCallsBoosterPage.css";

export default function OpenCallsBoosterPage() {

    const apiUrl = process.env.REACT_APP_API_URL;

    const createBoosterSchema = yup.object({
        email: yup.string().required().email(),
        acronym: yup.string().required(),
        fullTitle: yup.string().required(),
        duration: yup.number().required().positive(),
        projectPartners: yup.number().required().positive(),
        country: yup.string().required(),
        orri: yup.array().of(yup.string()).required().min(1),
        orriOptional1: yup.string(),
        orriOptional2: yup.string(),
        challenge: yup.string().required()
            .test(
                "len",
                "can be empty or with string and not more than 50",
                (val) => {
                    if (val === undefined) {
                        return true;
                    }
                    return countWords(val) <= 50;
                }
            ),
        achieved: yup.string().required()
            .test(
                "len",
                "can be empty or with string and not more than 50",
                (val) => {
                    if (val === undefined) {
                        return true;
                    }
                    return countWords(val) <= 50;
                }
            ),
        abstract: yup.string().required()
            .test(
                "len",
                "can be empty or with string and not more than 300",
                (val) => {
                    if (val === undefined) {
                        return true;
                    }
                    return countWords(val) <= 300;
                }
            ),
        template: yup.string().required(),
        declarations: yup.number().positive(),
        termsOfService: yup.boolean().oneOf([true], 'You have to accept')
    });

    const countWords = (s) => {
        s = s.replace(/(^\s*)|(\s*$)/gi, "");//exclude  start and end white-space
        s = s.replace(/[ ]{2,}/gi, " ");//2 or more space to 1
        s = s.replace(/\n /, "\n"); // exclude newline with a start spacing
        return s.split(' ').filter(function (str) { return str !== ""; }).length;
        //return s.split(' ').filter(String).length; - this can also be used
    }

    const [file, setFile] = useState(null);
    const [fileMulti, setFileMulti] = useState([]);
    const [orriWords, setOrriWords] = useState([]);
    const [startDate, setStartDate] = useState(new Date());

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            setValue('template', e.target.files[0]);
        }
    };

    const removeFile = (name) => {
        setFileMulti(fileMulti.filter(item => item.name !== name));
    }

    const addFiles = (f) => {
        if (f.length > 0) {
            Array.from(f).map(element => {
                const index = fileMulti.findIndex(inputObject => inputObject.name === element.name)
                if (index === -1) {
                    setFileMulti(oldArray => [...oldArray, element]);
                    setValue('declarations', 1);
                }
            });
            document.getElementById('declarations').value = null;
        }
    }

    const renderFileList = () => (<ul>
        {[...fileMulti].map((f, i) => (
            <li key={i}>{f.name} <button type="button" onClick={() => removeFile(f.name)}><FaTrash /></button></li>
        ))}
    </ul>)

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    };

    const { handleSubmit, register, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(createBoosterSchema)
    });

    const onSubmit = async data => {
        const templateFile = new File([file], `T$-${file.name}`, {
            type: file.type,
        });
        const formData = new FormData();
        formData.append("email", data.email);
        formData.append("acronym", data.acronym);
        formData.append("fullTitle", data.fullTitle);
        formData.append("duration", data.duration);
        formData.append("projectPartners", data.projectPartners);
        formData.append("country", data.country);
        formData.append("orri", data.orri);
        formData.append("orriOptional1", data.orriOptional1);
        formData.append("orriOptional2", data.orriOptional2);
        formData.append("challenge", data.challenge);
        formData.append("achieved", data.achieved);
        formData.append("date", startDate.toJSON());
        formData.append("abstract", data.abstract);
        for (const f of fileMulti) {
            formData.append('files', f);
        }
        formData.append('files', templateFile);
        fetch(`${apiUrl}/api/boosters/upload`, {
            method: 'POST',
            body: formData
        }).then(() => {
            reset({
                email: '',
                acronym: '',
                fullTitle: '',
                duration: '',
                projectPartners: '',
                country: '',
                orri: [],
                orriOptional1: '',
                orriOptional2: '',
                challenge: '',
                achieved: '',
                date: new Date(),
                abstract: '',
                termsOfService: false,
                declarations: 0
            });
            setFile(null);
            document.getElementById('template').value = null;
            setValue('template', null);
            setFileMulti([]);
            document.getElementById('declarations').value = null;
            setDeclarations();
            NotificationManager.success('Your proposal has been successfully uploaded!');
        });
    }

    const setDeclarations = () => {
        if (fileMulti.length === 0) {
            setValue('declarations', 0);
        }
    }

    const getOrriWords = () => {
        fetch(`${apiUrl}/api/grants/orri-words`)
            .then(response => response.json())
            .then(data => {
                setOrriWords(data);
            });
    }

    useEffect(() => {
        setDeclarations();
        if (orriWords.length === 0) {
            getOrriWords();
        }
    });

    return (
        <div className="page-container grants">
            <NotificationContainer />
            <Header className="header-grants" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">Grants</div>
                    <div className="title-title">Booster</div>
                </div>
            </div>
            <div id="breadcrumb">
                <div className="content">
                    Home / Grants / Open Calls / Booster
                </div>
            </div>
            <div id="booster">
                <div className="content">
                    <div className="downloads">
                        <a href="/Guidelines for applicants_updated.pdf" download className="download">Download guidelines</a>
                        <a href="/Template - ORRI Booster.doc" download className="download">Download template</a>
                        <a href="/Declaration of Honour.doc" download className="download">Download Declaration of Honor</a>
                    </div>
                    <Form noValidate onSubmit={handleSubmit(onSubmit)} id="createForm">
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="acronym">Acronym of the proposal</label>
                            <input type="text" {...register("acronym")} className={`form-control mt-1 ${errors.acronym ? 'is-invalid' : ''}`} id="acronym" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="fullTitle">Full title of the proposal</label>
                            <input type="text" {...register("fullTitle")} className={`form-control mt-1 ${errors.fullTitle ? 'is-invalid' : ''}`} id="fullTitle" />
                        </div>
                        <div className="form-group mt-3 position-relative">
                            <label className="form-label" htmlFor="duration">Duration (in months)</label>
                            <div className="dropdown-wrapper position-relative">
                                <select
                                    {...register("duration")}
                                    className={`form-control mt-1 ${errors.duration ? 'is-invalid' : ''}`}
                                    id="duration"
                                >
                                    <option value="8">8 months (booster)</option>
                                    <option value="12">12 months (incubator)</option>
                                </select>
                                <span className="dropdown-icon">▼</span>
                            </div>
                            {errors.duration && <div className="invalid-feedback">{errors.duration.message}</div>}
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="email">Contact email</label>
                            <input type="email" {...register("email")} className={`form-control mt-1 ${errors.email ? 'is-invalid' : ''}`} id="email" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="projectPartners">Number of project partners</label>
                            <input type="number" min={0} {...register("projectPartners")} className={`form-control mt-1 ${errors.projectPartners ? 'is-invalid' : ''}`} id="projectPartners" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="country">Country of project partners</label>
                            <input type="text" {...register("country")} className={`form-control mt-1 ${errors.country ? 'is-invalid' : ''}`} id="country" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="orri">ORRI words</label>
                            {
                                orriWords.map((item, index) => {
                                    return (
                                        <div key={index} className="form-check">
                                            <input className="form-check-input" type="checkbox" value={item.id} id={item.text} {...register("orri")} />
                                            <label className="form-check-label" htmlFor={item.text}>
                                                {item.text}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                            {errors.orri && (
                                <p className="error-message">{errors.orri.message}</p>
                            )}
                            <input type="text" {...register("orriOptional1")} placeholder="Free ORRI keyword" className={`form-control optional mt-3 ${errors.orriOptional1 ? 'is-invalid' : ''}`} id="orriOptional1" />
                            <input type="text" {...register("orriOptional2")} placeholder="Free ORRI keyword" className={`form-control optional mt-1 ${errors.orriOptional2 ? 'is-invalid' : ''}`} id="orriOptional2" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="challenge">Challenge to be addressed (50 words max)</label>
                            <textarea {...register("challenge")} className={`form-control mt-1 ${errors.challenge ? 'is-invalid' : ''}`} id="challenge"></textarea>
                            {errors.challenge && (
                                <p className="error-message">{errors.challenge.message}</p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="achieved">Changes to be achieved (50 words max)</label>
                            <textarea {...register("achieved")} className={`form-control mt-1 ${errors.achieved ? 'is-invalid' : ''}`} id="achieved"></textarea>
                            {errors.achieved && (
                                <p className="error-message">{errors.achieved.message}</p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label">Date of submission</label>
                            <DatePicker dateFormat="dd/MM/yyyy" className={`form-control mt-1`} selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="abstract">Abstract (300 words max)</label>
                            <textarea rows={14} {...register("abstract")} className={`form-control mt-1 ${errors.abstract ? 'is-invalid' : ''}`} id="abstract"></textarea>
                            {errors.achieved && (
                                <p className="error-message">{errors.abstract.message}</p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="template" className="form-label">Template - ORRI Booster</label>
                            <input type="file" accept="application/pdf" className={`form-control mt-1`} onChange={handleFileChange} id="template" />
                            {errors.template && (
                                <p className="error-message">{errors.template.message}</p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="declarations" className="form-label">Declaration of Honour</label>
                            <input
                                type="file"
                                id="declarations"
                                accept="application/pdf"
                                multiple
                                className="form-control mt-1"
                                onChange={(e) => addFiles(e.target.files)}
                            />
                            {fileMulti.length > 0 && (
                                <p className="mt-2 fs-6">
                                    {fileMulti.length} document{fileMulti.length > 1 ? 's' : ''} uploaded
                                </p>
                            )}
                            {renderFileList()}
                            {errors.declarations && (
                                <p className="error-message">{errors.declarations.message}</p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <input
                                type="checkbox"
                                id="accept"
                                {...register("termsOfService")}
                                onChange={handleCheckboxChange}
                            />
                            I have read and agree to the {" "}
                            <a href="/Privacy policy.pdf" download>
                                privacy policy
                            </a>
                            {errors.termsOfService && (
                                <p className="error-message">{errors.termsOfService.message}</p>
                            )}
                        </div>
                        <Button 
                            type="submit" 
                            variant="primary" 
                            className="btn btn-primary mt-5 register" 
                            form="createForm"
                            disabled={!isChecked}
                        >
                            Submit
                        </Button>
                    </Form>
                </div>
            </div>
            <Newsletter />
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    )
}