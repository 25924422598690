import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import logoHeader from "../../images/resources_logo.svg";
import ContactUs from "../../components/ContactUs";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import "./ProjectResourcesPage.css";

export default function ProjectResourcesPage() {
    return (
        <div className="page-container project-resources">
            <Header className="header-resources" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">&nbsp;</div>
                    <div className="title-title">Resources</div>
                    <div className="title-logo">
                        <img src={logoHeader} alt="reinforcing" />
                    </div>
                </div>
            </div>
            <div className="resources">
                <div className="content">
                    <div id="breadcrumb">
                        <div className="content">
                            Home / Resources
                        </div>
                    </div>
                    <div className="visual-materials">
                        <div className="visual-materials-title">
                            <h1 id="visual-materials-header">visual materials</h1>
                        </div>
                        <div className="visual-materials-posts">
                            <div className="resource">
                                <div className="resource-title">
                                    REINFORCING General Presentation
                                </div>
                                <a href="/REINFORCING_Presentation.pdf" download className="download">Download</a>
                            </div>
                            <div className="resource">
                                <div className="resource-title">
                                    REINFORCING Flyer
                                </div>
                                <a href="/REINFORCING_Flyer.pdf" download className="download">Download</a>
                            </div>
                            <div className="resource">
                                <div className="resource-title">
                                    REINFORCING Poster
                                </div>
                                <button className="download">Download</button>
                                <a href="/REINFORCING_Poster.pdf" download className="download">Download</a>
                            </div>
                            <div className="resource">
                                <div className="resource-title">
                                    REINFORCING ORRI Principales at Glance
                                </div>
                                <button className="download">Download</button>
                                <a href="/REINFORCING_ORRI_Principles_Glance_V2.pdf" download className="download">Download</a>
                            </div>
                            <div className="resource">
                                <div className="resource-title">
                                    REINFORCING ORRI Principles
                                </div>
                                <button className="download">Download</button>
                                <a href="/REINFORCING_ORRI_Principles.pdf" download className="download">Download</a>
                            </div>
                        </div>
                    </div>
                    <div className="press-release">
                        <div className="visual-materials-title">
                            <h1 id="visual-materials-header">press release</h1>
                        </div>
                        <div className="press-release-posts">
                            <div className="resource">
                                <div className="resource-title">
                                    REINFORCING Press Release
                                </div>
                                <a href="/REINFORCING_Press_Release.pdf" download className="download">Download</a>
                            </div>
                            <div className="resource">
                                <div className="resource-title">
                                    REINFORCING Press Release ORRI Platform
                                </div>
                                <button className="download">Download</button>
                                <a href="/ORRI_Platform_Press_Release.pdf" download className="download">Download</a>
                            </div>
                            <div className="resource">
                                <div className="resource-title">
                                    REINFORCING Press Release First Three Open Calls
                                </div>
                                <button className="download">Download</button>
                                <a href="/REINFORCING_Open_Calls_Press_Release_First_Three.pdf" download className="download">Download</a>
                            </div>
                        </div>
                    </div>
                    <div className="project-deliverables">
                        <div className="visual-materials-title">
                            <h1 id="visual-materials-header">project deliverables</h1>
                        </div>
                        <div className="project-deliverables-posts">
                            <div className="resource">
                                <div className="resource-title">
                                    D1.1 ORRI Taxonomy
                                </div>
                                <a href="/REINFORCING_ORRI_Taxonomy.pdf" download className="download">Download</a>
                            </div>
                            <div className="resource">
                                <div className="resource-title">
                                    D1.2 ORRI Guidelines
                                </div>
                                <div className="resource-subtitle-2">
                                    EU approval still pending
                                </div>
                                <a href="/REINFORCING_ORRI_Guidelines.pdf" download className="download">Download</a>
                            </div>
                            <div className="resource">
                                <div className="resource-title">
                                    D2.1 Community Engagement Roadmap
                                </div>
                                <div className="resource-subtitle-2">
                                    EU approval still pending
                                </div>
                                <a href="/REINFORCING_COMMUNITY_ENGAGEMENT_ROADMAP.pdf" download className="download">Download</a>
                            </div>
                            <div className="resource">
                                <div className="resource-title">
                                    D4.2 Cascading Grants supporting documents
                                </div>
                                <a href="/REINFORCING_Cascading_Grants_Support_Documents.pdf" download className="download">Download</a>
                            </div>
                            <div className="resource">
                                <div className="resource-title">
                                    D3.3 New ORRI resources and tools
                                </div>
                                <button className="download disabled">Download</button>
                                {/* <a href="/" download className="download">Download</a> */}
                            </div>
                            <div className="resource">
                                <div className="resource-title">
                                    D7.3 Policy brief
                                </div>
                                <button className="download disabled">Download</button>
                                {/* <a href="/" download className="download">Download</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}