import { Routes, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import React from 'react';

import OSSPlatformAddTrainingPage from "./pages/oss-platform/OSSPlatformAddTrainingPage";
import CallForEvaluatorsApplyPage from "./pages/open-calls/CallForEvaluatorsApplyPage";
import OSSPlatformServicesPage from "./pages/oss-platform/OSSPlatformServicesPage";
import ProjectResourcesPage from "./pages/project-resources/ProjectResourcesPage";
import OpenCallsIncubatorsPage from "./pages/open-calls/OpenCallsIncubatorsPage";
import OSSPlatformAddRriPage from "./pages/oss-platform/OSSPlatformAddRriPage";
import CallForEvaluatorsPage from "./pages/open-calls/CallForEvaluatorsPage";
import OpenCallsBoosterPage from "./pages/open-calls/OpenCallsBoosterPage";
import AboutAdvisoryBoardPage from "./pages/about/AboutAdvisoryBoardPage";
import AboutGlobalNetworkPage from "./pages/about/AboutGlobalNetworkPage";
import GrantsCurrentCallsPage from "./pages/open-calls/CurrentCallsPage";
import AboutProjectPlanPage from "./pages/about/AboutProjectPlanPage";
import AboutMethodologyPage from "./pages/about/AboutMethodologyPage";
import AboutConsortiumPage from "./pages/about/AboutConsortiumPage";
import OSSPlatformPage from "./pages/oss-platform/OSSPlatformPage";
import Nov2023CallPage from "./pages/closed-calls/Nov2023CallPage";
import Nov2024CallPage from "./pages/closed-calls/Nov2024CallPage";
import Mar2024CallPage from "./pages/closed-calls/Mar2024CallPage";
import Jul2024CallPage from "./pages/closed-calls/Jul2024CallPage";
import ConferencePage from "./pages/conference/ConferencePage";
import OpenCallsPage from "./pages/open-calls/OpenCallsPage";
import OrriMapMapPage from "./pages/orri-map/OrriMapMapPage";
import OrriMapPage from "./pages/orri-map/OrriMapPage";
import ContactPage from "./pages/contact/ContactPage";
import AboutPage from "./pages/about/AboutPage";
import BlogPage from "./pages/events/BlogPage";
import HomePage from "./pages/home/HomePage";
import PostPage from "./pages/PostPage";
import NotFound from "./pages/NotFound";
import client from "./api/apollo";

import './App.css';

function App() {
    return (
        <div id="page">
            <ApolloProvider client={client}>
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route exact path="/about" element={<AboutPage />} />
                    <Route exact path="/about/consortium" element={<AboutConsortiumPage />} />
                    <Route exact path="/about/advisory-board" element={<AboutAdvisoryBoardPage />} />
                    <Route exact path="/about/project-plan" element={<AboutProjectPlanPage />} />
                    <Route exact path="/about/global-network" element={<AboutGlobalNetworkPage />} />
                    <Route exact path="/open-calls/open-calls" element={<OpenCallsPage />} />
                    <Route exact path="/open-calls/open-calls/incubators" element={<OpenCallsIncubatorsPage />} />
                    <Route exact path="/open-calls/open-calls/booster" element={<OpenCallsBoosterPage />} />
                    <Route exact path="/open-calls/current-calls" element={<GrantsCurrentCallsPage />} />
                    <Route exact path="/open-calls/calls-evaluation" element={<CallForEvaluatorsPage />} />
                    <Route exact path="/open-calls/calls-evaluation/apply" element={<CallForEvaluatorsApplyPage />} />
                    <Route exact path="/closed-calls/november-2023-call" element={<Nov2023CallPage />} />
                    <Route exact path="/closed-calls/november-2024-call" element={<Nov2024CallPage />} />
                    <Route exact path="/closed-calls/march-2024-call" element={<Mar2024CallPage />} />
                    <Route exact path="/closed-calls/july-2024-call" element={<Jul2024CallPage />} />
                    <Route exact path="/oss-platform" element={<OSSPlatformPage />} />
                    <Route exact path="/oss-platform/add-rri" element={<OSSPlatformAddRriPage />} />
                    <Route exact path="/oss-platform/add-training" element={<OSSPlatformAddTrainingPage />} />
                    <Route exact path="/oss-platform/*" element={<OSSPlatformServicesPage />} />
                    <Route exact path="/methodology" element={<AboutMethodologyPage />} />
                    <Route exact path="/news" element={<BlogPage />} />
                    <Route exact path="/project-resources" element={<ProjectResourcesPage />} />
                    <Route exact path="/conference" element={<ConferencePage />} />
                    <Route exact path="/contact" element={<ContactPage />} />
                    <Route exact path="/orri-map" element={<OrriMapPage />} />
                    <Route exact path="/orri-map/map" element={<OrriMapMapPage />} />
                    <Route path="/news/:slug" element={<PostPage />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </ApolloProvider>
        </div>
    );
}

export default App;
