import logoTitle from "../../images/oss-platform/services_title_logo.svg";
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import InfoIcon from "../../images/oss-platform/info_icon.svg";
import Newsletter from "../../components/Newsletter";
import ContactUs from "../../components/ContactUs";
import React, { useEffect, useState } from 'react';
import Header from "../../components/Header";
import { FaArrowLeft } from "react-icons/fa6";
import { CgArrowLongRight } from "react-icons/cg";
import Footer from "../../components/Footer";
import ReactPaginate from "react-paginate";
import { useLocation } from 'react-router-dom';

import Icon1 from "../../images/oss-platform/tab_icon_1.svg";
import Icon2 from "../../images/oss-platform/tab_icon_2.svg";
import Icon3 from "../../images/oss-platform/tab_icon_3.svg";
import Icon4 from "../../images/oss-platform/tab_icon_4.svg";
import Icon5 from "../../images/oss-platform/tab_icon_5.svg";

import TabCardIcon1 from "../../images/oss-platform/tab_card_icon_1.svg";
import TabCardIcon2 from "../../images/oss-platform/tab_card_icon_2.svg";
import TabCardIcon3 from "../../images/oss-platform/tab_card_icon_3.svg";
import TabCardIcon4 from "../../images/oss-platform/tab_card_icon_4.svg";
import TabCardIcon5 from "../../images/oss-platform/tab_card_icon_5.svg";
import TabCardIcon6 from "../../images/oss-platform/tab_card_icon_6.svg";

import funded from "../../images/oss-platform/funded.png";

import { OSSPlatformServicesSlibingPage } from "./slibing/OSSPlatformServicesSlibingPage";

import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { NotificationContainer, NotificationManager } from 'react-notifications';
import "react-datepicker/dist/react-datepicker.css";
import "react-notifications/lib/notifications.css";

import "./OSSPlatformServicesPage.css";

export default function OSSPlatformServicesPage() {

    const [showResults, setShowResults] = useState(false);
    const [selectedResult, setSelectedResult] = useState(null);
    const [results, setResults] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [totalPages, setTotalPages] = useState(0);
    const [metadata, setMetadata] = useState({});
    const [searchText, setSearchText] = useState('');
    const location = useLocation();
    const postsPerPage = 10;

    const handleTabChange = (number) => {
        if (number === 2) {
            setSelectedItems([]);
            setSelectedResult(null);
            setResults([]);
            reset2();
            setSearchText('');
            setShowResults(false);
            setCurrentPage(1);
        }
    }

    const selectItem = (item) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter(selectedItem => selectedItem !== item);
            } else {
                return [...prevSelectedItems, item];
            }
        });
    }

    // const handleSubmit = () => {
    //     NotificationManager.error('Search is not available yet', 'Error!');
    // }

    const [selectedYear, setSelectedYear] = useState(null);

    const handleYearChange = (date) => {
        setSelectedYear(date);
    };

    // ---------------------------- NEW TOOL FORM ---------------------------- //

    const currentYear = new Date().getFullYear();

    const [wordLimitReached50, setWordLimitReached50] = useState(false);

    const handleWordTitleLimit = (e) => {
        const inputValue = e.target.value;
        const words = inputValue.trim().split(/\s+/);

        if (words.length > 50) {
            e.preventDefault();
            const limitedWords = words.slice(0, 50).join(' ');
            e.target.value = limitedWords;
            setWordLimitReached50(true);
        } else {
            setWordLimitReached50(false);
        }
    };

    const [wordLimitReached50Authors, setWordLimitReached50Authors] = useState(false);

    const handleWordTitleLimitAuthors = (e) => {
        const inputValue = e.target.value;
        const words = inputValue.trim().split(/\s+/);

        if (words.length > 50) {
            e.preventDefault();
            const limitedWords = words.slice(0, 50).join(' ');
            e.target.value = limitedWords;
            setWordLimitReached50Authors(true);
        } else {
            setWordLimitReached50Authors(false);
        }
    };

    const [wordLimitReached300, setWordLimitReached300] = useState(false);

    const handleWordSummaryLimit = (e) => {
        const inputValue = e.target.value;
        const words = inputValue.trim().split(/\s+/);

        if (words.length > 300) {
            e.preventDefault();
            const limitedWords = words.slice(0, 300).join(' ');
            e.target.value = limitedWords;
            setWordLimitReached300(true);
        } else {
            setWordLimitReached300(false);
        }
    };

    const createResourceSchema = yup.object({
        resource_name: yup.string().required('Resource name is required'),
        resource_authors: yup.string().required('Resource authors are required'),
        summary: yup.string().required('Summary is required'),
        publication_year: yup.number().required('Publication year is required').min(1700, 'Publication year cannot be before 1700').max(currentYear, `Publication year cannot exceed ${currentYear}`).typeError('Publication year must be a number'),
        link: yup.string().required('Link is required').url('Must be a valid URL'),
        resource_type: yup.string().required('Resource type is required'),
        keywords: yup.array().of(yup.string()).required().min(1, 'At least one keyword must be selected'),
        potential_users: yup.array().of(yup.string()).required().min(1, 'At least one potential user must be selected')
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    const { handleSubmit, register, reset, formState: { errors } } = useForm({
        resolver: yupResolver(createResourceSchema)
    });

    const { register: register2, handleSubmit: handleSubmit2, reset: reset2, formState: { } } = useForm();

    const searcherOnSubmit = data => {
        setSearchText(data.search);
        reset2();
        setCurrentPage(1);
        showToolResults(null, data.search);
    }

    const [selectedImage, setSelectedImage] = useState(null);
    const [potentialUsers, setPotentialUsers] = useState([]);
    const [resourceTypes, setResourceTypes] = useState([]);
    const [keywords, setKeywords] = useState([]);

    useEffect(() => {
        fetch(`${apiUrl}/api/rri-tools/resource-types`)
            .then(response => response.json())
            .then(data => {
                setResourceTypes(data);
            })
            .catch(error => {
                console.error("Error fetching resource types:", error);
            });

        fetch(`${apiUrl}/api/rri-tools/keywords`)
            .then(response => response.json())
            .then(data => {
                setKeywords(data);
            })
            .catch(error => {
                console.error("Error fetching keywords:", error);
            });

        fetch(`${apiUrl}/api/rri-tools/resource-potential-users`)
            .then(response => response.json())
            .then(data => {
                setPotentialUsers(data);
            })
            .catch(error => {
                console.error("Error fetching potential users:", error);
            });
    }, []);

    const handleImageChange = event => {
        if (event.target.files[0]) {
            setSelectedImage(event.target.files[0]);
        }
    };

    const showToolResults = (selected, text) => {
        let page = currentPage;
        if (selected) {
            setCurrentPage(selected);
            page = selected;
        }
        if (typeof text === 'undefined') {
            text = "";
        }
        if (text !== '') {
            setSelectedItems([]);
        }
        fetch(`${apiUrl}/api/rri-tools/filtered?text=${text}&page=${page}&size=${postsPerPage}&keywords=${selectedItems.join(',')}`)
            .then(response => {
                const pagination = JSON.parse(response.headers.get('x-pagination'));
                setMetadata(pagination);
                setTotalPages(pagination.TotalPages);
                return response.json()
            })
            .then(data => {
                setResults(data);
                setShowResults(true);
            })
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        document.getElementById('image').value = '';
    };

    const selectResult = (result) => {
        fetch(`${apiUrl}/api/rri-tools/${result}`)
            .then(response => response.json())
            .then(data => {
                setSelectedResult(data);
            });
    };

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('authors', data.resource_authors);
        formData.append('title', data.resource_name);
        formData.append('summary', data.summary);
        formData.append('yearOfPublication', data.publication_year);
        formData.append('url', data.link);

        formData.append('resourceTypes', data.resource_type);
        formData.append('keywords', data.keywords ? data.keywords.join(',') : '');
        formData.append('resourcePotentialUsers', data.potential_users ? data.potential_users.join(',') : '');

        if (selectedImage) {
            formData.append('file', selectedImage);
        }

        try {
            const response = await fetch(`${apiUrl}/api/rri-tools`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                NotificationManager.success('Your form has been successfully uploaded', 'Submitted!');

                reset({
                    resource_name: '',
                    resource_authors: '',
                    summary: '',
                    publication_year: 0,
                    image_resolution: '',
                    link: '',
                    resource_type: '',
                    keywords: [],
                    potential_users: []
                });

                setSelectedImage(null);
                setSelectedYear(null);
            } else {
                NotificationManager.error('A problem occurred while submitting the form', 'Error!');
                console.error('Error:', await response.text());
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
    };

    return (
        <div className="page-container oss-platform-services">
            <NotificationContainer />
            <Header className="header-oss-platform-services" />
            <div id="title">
                <div className="content">
                    <div className="banner">
                        <div className="banner-text">
                            <div className="banner-title">
                                <h1>orri one stop source</h1>
                            </div>
                            <div className="banner-subtitle">
                                {location.pathname === "/oss-platform/services/grants" && (
                                    <h3>grants</h3>
                                )}
                                {location.pathname === "/oss-platform/services/orri-community" && (
                                    <h3>orri community</h3>
                                )}
                                {location.pathname === "/oss-platform/services/tools" && (
                                    <h3>tools</h3>
                                )}
                                {location.pathname === "/oss-platform/services/training" && (
                                    <h3>training</h3>
                                )}
                                {location.pathname === "/oss-platform/services/pathways" && (
                                    <h3>pathways</h3>
                                )}
                                {location.pathname === "/oss-platform/services/form" && (
                                    <h3>form</h3>
                                )}
                                {location.pathname === "/oss-platform/services/tools/own-tool" && (
                                    <h3>add your own orri tool</h3>
                                )}
                            </div>
                        </div>
                        <div className="banner-logo">
                            <img src={logoTitle} alt="reinforcing" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="introduction">
                <div className="content">
                    <div className="introduction-body">
                        {location.pathname === "/oss-platform/services/grants" && (
                            <h5>
                                REINFORCING provides financial support to European
                                organizations in the form of grants to enable and
                                support their journey towards open and responsible
                                research and innovation.
                            </h5>
                        )}
                        {location.pathname === "/oss-platform/services/orri-community" && (
                            <h5>
                                From experienced organizations to facilitators,
                                this is where you can find peers who, just like you,
                                have had their own ORRI journeys and can share more
                                about their experiences.
                            </h5>
                        )}
                        {location.pathname === "/oss-platform/services/tools" && (
                            <h5>
                                This is where you find a wealth of useful resources
                                to support you in designing your ORRI project with
                                concrete tools and best practices for implementing ORRI.
                            </h5>
                        )}
                        {location.pathname === "/oss-platform/services/training" && (
                            <h5>
                                Training is essential to acquire the right skillset
                                you need to implement ORRI projects. Here you can find
                                training modules on general and specific topics related
                                to ORRI.
                            </h5>
                        )}
                        {location.pathname === "/oss-platform/services/pathways" && (
                            <h5>

                            </h5>
                        )}
                        {location.pathname === "/oss-platform/services/form" && (
                            <h5>
                                REINFORCING provides the possibility of submitting
                                your own tools through the following form.
                            </h5>
                        )}
                    </div>
                </div>
            </div>
            <div className="services">
                <div className="services-tabs pt-4">
                    <ul className="nav nav-tabs justify-content-center">
                        <li className="nav-item">
                            <a
                                className={`nav-link ${location.pathname === "/oss-platform/services/grants" ? 'active' : ''}`}
                                aria-current="page"
                                href="/oss-platform/services/grants">
                                <div className="tab_title">
                                    <img src={Icon1} alt="icon_grants" />
                                    <h1>Grants</h1>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${location.pathname.includes("/oss-platform/services/orri-community") ? 'active' : ''}`}
                                href="/oss-platform/services/orri-community">
                                <div className="tab_title">
                                    <img src={Icon2} alt="icon_orri_community" />
                                    <h1>ORRI Community</h1>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${location.pathname === "/oss-platform/services/tools" ? 'active' : ''}`}
                                href="/oss-platform/services/tools">
                                <div className="tab_title">
                                    <img src={Icon3} alt="icon_tools" />
                                    <h1>Tools</h1>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item disabled">
                            <a
                                className={`nav-link ${location.pathname === "/oss-platform/services/training" ? 'active' : ''}`}
                                href="/oss-platform/services/training">
                                <div className="tab_title">
                                    <img src={Icon4} alt="icon_training" />
                                    <h1>Training</h1>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item disabled">
                            <a
                                className={`nav-link ${location.pathname === "/oss-platform/services/pathways" ? 'active' : ''}`}
                                href="/oss-platform/services/pathways">
                                <div className="tab_title">
                                    <img src={Icon5} alt="icon_pathways" />
                                    <h1>Pathways</h1>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div className="content-2">
                        <div className="services-tabs-body">
                            {location.pathname === "/oss-platform/services/grants" && (
                                <div className="services-tabs-body-data">
                                    <div className="services-tabs-body-data-title">
                                        <h1>Information</h1>
                                    </div>
                                    <div className="services-tabs-body-data-cards">
                                        <div className="services-tabs-cards-info">
                                            <h3>Find general information about funding</h3>
                                            <div className="services-tabs-card">
                                                <a href="/open-calls/open-calls" id="open-call-link" rel="noreferrer">General Information
                                                    <img id="card_icon_1" src={TabCardIcon1} alt="card_icon" />
                                                </a>

                                            </div>
                                        </div>
                                        <div className="services-tabs-cards-info">
                                            <h3>Find funding for your ORRI project</h3>
                                            <div className="services-tabs-card">
                                                <a href="/open-calls/current-calls" id="current-call-link" rel="noreferrer">Current Call
                                                    <img id="card_icon_2" src={TabCardIcon2} alt="card_icon" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="services-tabs-cards-info disabled">
                                            <h3>Info on ORRI Booster and Incubator projects</h3>
                                            <div className="services-tabs-card">
                                                <a href="/#" id="previous-call-link" rel="noreferrer">Previous Call
                                                    <img id="card_icon_4" src={TabCardIcon4} alt="card_icon" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {location.pathname === "/oss-platform/services/orri-community" && (
                                <div className="services-tabs-body-data">
                                    <div className="services-tabs-body-data">
                                        <div className="services-tabs-body-data-cards" id="services-tabs-body-data-cards-2">
                                            <div className="services-tabs-cards-info">
                                                <div className="services-tabs-card">
                                                    <a href="/orri-map" id="open-call-link">ORRI Map
                                                        <img id="card_icon_3" src={TabCardIcon3} alt="card_icon" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="services-tabs-cards-info">
                                                <div className="services-tabs-card">
                                                    <a href="/oss-platform/services/orri-community/grantees" id="current-call-link">REINFORCING Grantees
                                                        <img id="card_icon_5" src={TabCardIcon5} alt="card_icon" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="services-tabs-cards-info">
                                                <div className="services-tabs-card">
                                                    <a href="/oss-platform/services/orri-community/slibing-projects-platforms" id="previous-call-link"
                                                        className="two-lines">
                                                        <span>Sibling Projects & Platforms</span>
                                                        <img id="card_icon_6" src={TabCardIcon6} alt="card_icon" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {location.pathname === "/oss-platform/services/orri-community/grantees" && (
                                <>
                                    <div className="breadcrumb">
                                        Orri Community / <span>Grantees</span>
                                    </div>
                                    <a className="back" href="/oss-platform/services/orri-community">
                                        <FaArrowLeft />Back to ORRI Community
                                    </a>
                                    <div className="grantee-background-left"></div>
                                    <div className="grantee-background"></div>
                                    <div className="grantee-background-right"></div>
                                    <div className="grantee-title">
                                        Get to know our grantees and their projects
                                    </div>
                                    <div className="services-tabs-body-data-grantees">
                                        <div className="services-tabs-body-data-cards">
                                            <div className="services-tabs-cards-info">
                                                <div className="services-tabs-card">
                                                    <div className="title">Responsible Open Innovation</div>
                                                    <div className="opencall">Grantees from the 1st Open Call</div>
                                                    <div className="callDate">Boosters Nov' 23 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                <a href="/oss-platform/services/orri-community/grantees/boosters-nov-23"></a>
                                            </div>
                                            <div className="services-tabs-cards-info">
                                                <div className="services-tabs-card">
                                                    <div className="title">ORRI in the Balkans</div>
                                                    <div className="opencall">Grantees from the 2nd Open Call</div>
                                                    <div className="callDate">Incubators Mar' 24 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                <a href="/oss-platform/services/orri-community/grantees/incubators-mar-24"></a>
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <div className="title">Multi-stakeholder engagement in the EU Missions</div>
                                                    <div className="opencall">Grantees from the 3rd Open Call</div>
                                                    <div className="callDate">Incubators July 24 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                <a href="/oss-platform/services/orri-community/grantees/incubators-jul-24"></a>
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <div className="title">REINFORCING</div>
                                                    <div className="opencall">4th Open Call</div>
                                                    <div className="callDate">Boosters Nov' 24 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                { /*<a href="/#"
                                                    onClick={(event) => { event.preventDefault(); setGrantee(5); }}>
                                                </a>*/ }
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <div className="title">REINFORCING</div>
                                                    <div className="opencall">5th Open Call</div>
                                                    <div className="callDate">Boosters Mar' 25 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                { /*<a href="/#"
                                                    onClick={(event) => { event.preventDefault(); setGrantee(6); }}>
                                                </a>*/ }
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <div className="title">REINFORCING</div>
                                                    <div className="opencall">6th Open Call</div>
                                                    <div className="callDate">Incubators July 25 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                { /*<a href="/#"
                                                    onClick={(event) => { event.preventDefault(); setGrantee(7); }}>
                                                </a>*/ }
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <div className="title">REINFORCING</div>
                                                    <div className="opencall">7th Open Call</div>
                                                    <div className="callDate">Boosters Nov' 25 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                { /*<a href="/#"
                                                    onClick={(event) => { event.preventDefault(); setGrantee(8); }}>
                                                </a>*/ }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {location.pathname === "/oss-platform/services/orri-community/grantees/boosters-nov-23" && (
                                <>
                                    <div className="breadcrumb">
                                        Orri Community / Grantees / <span>REINFORCING First Open Call</span>
                                    </div>
                                    <a className="back" href="/oss-platform/services/orri-community/grantees">
                                        <FaArrowLeft />Back to Grantees
                                    </a>
                                    <div className="grantee-opencall-subtitle">
                                        1st OPEN CALL ON RESPONSIBLE OPEN INNOVATION
                                    </div>
                                    <div className="grantee-opencall-title">
                                        Boosters
                                    </div>
                                    <div className="grantee-opencall-text">
                                        In November 2023, we launched <u>REINFORCING’s first open call (Boosters Call)</u> for Responsible Open Innovation projects.
                                        The call targeted applicants with proven experience in ORRI practices and addressed three specific challenges:
                                        multi-stakeholder engagement, intellectual property rights mechanisms as well as impact assessment of responsibility.
                                    </div>
                                    <div className="grantee-opencall-projects-text">
                                        Thanks to a meticulous evaluation process supported by External Evaluators, the following 6 projects were selected.
                                    </div>
                                    <div className="grantee-opencall-projects">
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                CoCOI FoodTech
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                (Co-Creation of Open Innovation in Food Technology)
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by the Institute for Food Technology of Novi Sad in Serbia, this project aims to enhance multi-stakeholder
                                                engagement throughout the research process in food and feed technology.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                aRRIba
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Early Age Entrepreneurship Promo(tion to Boost Scientific-Technological Vocation)
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Coordinated by “Fundación Centro Europeo de Empresas e Innovación de Murcia”, based in Spain, this project
                                                focuses on fostering scientific entrepreneurship among primary school students in the Spanish Region of Murcia, utilizing a multi-stakeholder approach.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                D-EUROB models
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                (Business Models Facilitating Open Science in Digital Euro Research)
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Coordinated by Alis Grave Nil in Bulgaria, this project aims to adapt the company’s business model to support Open Research and
                                                responsible sharing of research findings concerning the Digital Euro, encouraging collaboration and innovation within the digital currency ecosystem.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                DDEEP
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                (Disabled Data European Engagement Path)
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by the Italian limited liability company, FTS, this project seeks to standardize an ORRI-based approach, examining the needs for
                                                disability data across Europe, and expand the project through multi-stakeholder engagement. The initiative builds upon the
                                                launch in November 2022 of Fightthestroke.org, in which FTS holds a 48% ownership stake.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                IP2024
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                (Impact Pathways 2024 – An Interactive Tool to Integrate Societal Impact and RRI Principles in R&I Processes)
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by SoScience, a pioneering European startup dedicated to Responsible Research and Innovation, this project aims to co-design and develop a free online interactive
                                                tool to assist organizations, consortia, and researchers in focusing on key impact elements for developing responsible R&I projects.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                TOG Eye-Tracking
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                (Together To Go Eye - Tracking)
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by Fondazione Together To Go Onlus in Italy, this project aims to develop a new cooperative methodology for designing
                                                rehabilitation models and tools, making them accessible to a broader audience beyond researchers and treatment centers.
                                                Additionally, it will  produce two open-source software for eye pointing, an instruction manual for the research process developed through the project.
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {location.pathname === "/oss-platform/services/orri-community/grantees/incubators-mar-24" && (
                                <>
                                    <div className="breadcrumb">
                                        Orri Community / Grantees / <span>REINFORCING Second Open Call</span>
                                    </div>
                                    <a className="back" href="/oss-platform/services/orri-community/grantees">
                                        <FaArrowLeft />Back to Grantees
                                    </a>
                                    <div className="grantee-opencall-subtitle">
                                        2nd OPEN CALL ON RESPONSIBLE OPEN INNOVATION
                                    </div>
                                    <div className="grantee-opencall-title">
                                        Incubators
                                    </div>
                                    <div className="grantee-opencall-text">
                                        In March 2024, REINFORCING launched its <a href="https://reinforcing.eu/news/reinforcing-releases-its-second-orri-open-call-incubators-call-on-the-balkans">2nd ORRI Open Call (Incubators Call)</a> focused
                                        on the Balkans, aimed at enhancing Open and Responsible Research and Innovation (ORRI) in the region.
                                        The selected projects showcase strong expertise in ORRI and address key challenges, including researcher
                                        empowerment, institutional change, and strengthening research ecosystems.
                                    </div>
                                    <div className="grantee-opencall-projects-text">
                                        Following a rigorous evaluation process supported by external evaluators, eight projects were selected.
                                    </div>
                                    <div className="grantee-opencall-projects">
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                YO-FI
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Youth Open Research and Innovation Fellowship
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by Digital Wisdom in collaboration with Youth Centar Drenas, this initiative focuses on young researchers from the NPO sector.
                                                It aims to bridge gaps in access to ORRI tools and mentorship while promoting interdisciplinary collaboration. Key outputs include an ORRI guide,
                                                workshops, and an ambassador platform to foster cross-border collaborations.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                CORIB
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Collaborative Open Research and Innovation for the Balkans
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by Croatian institutions, <a href="https://www.linkedin.com/company/stemwise/posts/" target="_blank" rel="noreferrer">StemWise</a> in
                                                collaboration with <a href="https://www.linkedin.com/company/center-of-technology-transfer-llc/" target="_blank" rel="noreferrer">Center of Technology Transfer (CTT)</a>,
                                                CORIB aims to embed ORRI principles across academia, industry, and government. Through workshops, mentoring, and
                                                the establishment of ORRI Balkans, it will drive responsible, inclusive research. A highlight
                                                is an ORRI-oriented matchmaking event at the Horizon Europe Summit, promoting international collaboration.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                REINFORSEA
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                This project, led by <a href="https://www.rmri.ro/Home/Home.html?lang=en" target="_blank" rel="noreferrer">NIMRD</a> and LOM, targets marine research in the Black Sea,
                                                focusing on integrating ORRI practices to align research with societal needs. The project
                                                will develop ORRI guidelines, engage stakeholders, and strengthen regional collaboration in marine research.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                RobotORRI
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by robotics and research institutions in Southeast Europe such as <a href="https://www.linkedin.com/company/hub-9/" target="_blank" rel="noreferrer">HUB9</a>,
                                                or <a href="https://www.linkedin.com/company/etf-robotics/" target="_blank" rel="noreferrer">ETF Robotics</a>, RobotORRI seeks to create an innovative robotics
                                                ecosystem by integrating ORRI and Diversity, Equity, and Inclusion (DEI) principles.
                                                The project will map 100 researchers and 10 industry partners, driving responsible robotics development.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                CORYA
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                This project, led by <a href="https://beyondbarriers.org/" target="_blank" rel="noreferrer">Beyond Barriers Association</a> and <a href="https://www.zsi.at/" target="_blank" rel="noreferrer">Zentrum für Soziale Innovation</a>,
                                                focuses on promoting citizen science in Albania’s youth work sector. Key activities
                                                include an e-learning module, study visits, and a seminar to engage young researchers and practitioners in responsible research.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                ORBIT
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Open Research Bulgarian Incubator
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by <a href="https://www.uni-sofia.bg/eng" target="_blank" rel="noreferrer">Sofia University</a> and NGO Links, <a href="https://www.linkedin.com/company/orbit-project" target="_blank" rel="noreferrer">ORBIT</a> aims
                                                to consolidate and expand ORRI practices in Bulgaria. It will establish the first national ORRI Virtual Lab, develop a
                                                toolkit for researchers, and contribute to bridging the gap between science legislation and ORRI.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                SuDe2Re
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                This project aims to enhance ORRI integration in addressing local environmental challenges. Led by institutions in Serbia and
                                                Bosnia, SuDe2Re focuses on capacity building and developing a scalable ORRI model for the region, promoting sustainable research practices.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                INSPIRE
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Inclusive Co-Creation for Sustainable Research Impact
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by a regional research institute, INSPIRE aims to embed ORRI principles into research
                                                practices while promoting inclusivity and transparency. The project will focus on co-creation
                                                tools, citizen engagement, and balancing open science with intellectual property rights.
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {location.pathname === "/oss-platform/services/orri-community/grantees/incubators-jul-24" && (
                                <>
                                    <div className="breadcrumb">
                                        Orri Community / Grantees / <span>REINFORCING Third Open Call</span>
                                    </div>
                                    <a className="back" href="/oss-platform/services/orri-community/grantees">
                                        <FaArrowLeft />Back to Grantees
                                    </a>
                                    <div className="grantee-opencall-subtitle">
                                        3rd OPEN CALL ON RESPONSIBLE OPEN INNOVATION
                                    </div>
                                    <div className="grantee-opencall-title">
                                        Incubators
                                    </div>
                                    <div className="grantee-opencall-text">
                                        In July 2024, REINFORCING launched its <a href="https://reinforcing.eu/news/reinforcing-third-open-call-on-missions-webinar" target="_blank" rel="noreferrer">3rd Open Call (Incubators Call)</a> on
                                        EU Missions. The call supported organizations and territories working
                                        to engage stakeholders, including citizens, in research, innovation,
                                        and policymaking processes related to EU Missions. The projects were
                                        selected for their innovative approaches to stakeholder engagement.
                                    </div>
                                    <div className="grantee-opencall-projects-text">
                                        Following a rigorous evaluation process supported by external evaluators, eight projects were selected.
                                    </div>
                                    <div className="grantee-opencall-projects">
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                SALIENCE
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Soil Alliance for Sustainable Development in the Mediterranean
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by Academy of <a href="https://akep.eu/" target="_blank" rel="noreferrer">Entrepreneurship Astiki Etaireia (AKEP)</a>
                                                , <a href="https://fundacionaland.com/" target="_blank" rel="noreferrer">Fundacion Aland</a> and <a href="https://www.miteco.gob.es/es/ceneam/recursos/quien-es-quien/ago11.html" target="_blank" rel="noreferrer">Grup Balear D’Ornitologia i Defensa de la Naturalesa</a>
                                                , <b>SALIENCE</b> addresses challenges in stakeholder engagement for soil restoration
                                                in rural Mediterranean areas. By increasing soil literacy and fostering inclusive
                                                collaboration, the project promotes sustainable land management practices while
                                                building a culture of responsibility among local communities.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                CoBlue
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Co-Producing Knowledge for a Sustainable Blue Economy
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by <a href="https://www.uvigo.gal/es" target="_blank" rel="noreferrer">Universidade de Vigo (UVigo)</a> and <a href="https://www.up.pt/feup/pt/" target="_blank" rel="noreferrer">Faculdade de Engenharia da Universidade do Porto (FEUP)</a>
                                                , <b>CoBlue</b> bridges the gap between citizen knowledge and policy-making in the blue economy.
                                                Through a citizen assembly and participatory governance, the project advances the EU’s Starfish
                                                2030 Mission, focusing on ecosystem regeneration, zero pollution, and equitable marine policies.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                WATER
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Engaging Citizens for Urban Water Management in Vollsmose
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                <a href="https://www.vcsdenmark.com/" target="_blank" rel="noreferrer">VandCenter Syd (VCS)</a>
                                                , <a href="https://www.niras.com/" target="_blank" rel="noreferrer">NIRAS</a> and
                                                the <a href="https://www.sdu.dk/en" target="_blank" rel="noreferrer">University of Southern Denmark (SDU)</a> collaborate
                                                on <b>WATER</b> to improve water management in Vollsmose, Denmark’s most diverse and disadvantaged
                                                area. Using innovative methods like «Water Walks» and pop-up events, the project empowers
                                                citizens, especially young people, to co-create solutions for climate adaptation and biodiversity.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                MULTI-ENGAGE
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Inclusive Ecosystems for the Cancer EU Mission
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                <a href="https://fisabio.san.gva.es/es/" target="_blank" rel="noreferrer">FISABIO</a> and <a href="https://valenciainnovationcapital.com/" target="_blank" rel="noreferrer">Vic-Valencia innovation capital</a> lead <b>MULTI-ENGAGE</b> to
                                                address cancer challenges through multi-stakeholder engagement. The project implements a
                                                training program for researchers and citizens to co-develop innovative solutions, ensuring
                                                transparency, inclusivity, and impactful contributions to the EU Mission on Cancer.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                ROOTSS
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Educating Stakeholders on Soil Science
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by the <a href="http://talaj.hu/" target="_blank" rel="noreferrer">Hungarian Soil Science Society</a> and <a href="https://agrikulti.hu/en/homepage/" target="_blank" rel="noreferrer">Agri Kulti Nonprofit Ltd.</a>
                                                , <b>ROOTSS</b> fosters local living labs to address soil health challenges. The project
                                                promotes soil literacy through community engagement, scientific demonstrations, and
                                                transparent data-sharing, creating a collaborative platform for regenerative agriculture.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                SE4HuBlE
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Stakeholder Engagement for the Hutovo Blato Ecosystem
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                <a href="https://www.cirp.ba/" target="_blank" rel="noreferrer">Centar za Investicije i Razvoj Poduzetništva (CIRP)</a> and <a href="https://stemwise.eu/" target="_blank" rel="noreferrer">STEMwise</a> tackle
                                                biodiversity threats in Bosnia and Herzegovina’s Hutovo Blato wetland. By integrating
                                                ORRI principles, the project enhances community involvement, builds capacity for
                                                conservation, and aligns with EU biodiversity and water sustainability strategies.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                CLIMAS
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Transforming Polluted Cities through Collaboration
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by Digital Wisdom and <a href="https://www.eko-kolektiv.si/institut-za-ekologijo/" target="_blank" rel="noreferrer">Inštitut za Ekologijo</a>
                                                , <b>CLIMAS</b> fosters climate-neutral urban development by transferring solutions from
                                                Ljubljana to Skopje. Through AI-enhanced tools and multi-stakeholder engagement, the project
                                                drives emission reductions and promotes sustainable governance in highly polluted cities.
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                CLeAR-CLIMATE-CAPA-ORRI
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Community-Driven Climate Resilience
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                The <a href="https://www.golea.si/" target="_blank" rel="noreferrer">Goriška Local Energy Agency (GOLEA)</a> and <a href="https://www.stritih.com/sl/domov/" target="_blank" rel="noreferrer">Svetovanje za Trajnostni Razvoj, d.o.o.</a> implement
                                                an innovative Financial Participatory Approach (FPA) to engage citizens in climate
                                                adaptation efforts in Slovenia. By fostering local solutions to water-related risks,
                                                the project builds capacity, increases awareness, and drives inclusive governance.
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <OSSPlatformServicesSlibingPage />
                            {location.pathname === "/oss-platform/services/tools" && !showResults && (
                                <div className="services-tabs-body-data">
                                    <div className="services-tabs-tools-introduction">
                                        <h2>You can search for a topic:</h2>
                                        <a href="/oss-platform/services/tools/own-tool" id="own-orri-tool-btn">ADD YOUR OWN ORRI TOOL</a>
                                    </div>
                                    {<div className="searcher">
                                        <div className="search-group">
                                            <div className="input-wrapper">
                                                <Form noValidate onSubmit={handleSubmit2(searcherOnSubmit)} id="searchForm">
                                                    <input type="search" id="search-input" className="form-control" placeholder="Search..."
                                                        aria-label="Search" aria-describedby="search-addon"
                                                        {...register2("search")}
                                                    />
                                                    <button type="submit" form="searchForm" className="search-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                                        </svg>
                                                    </button>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="services-tabs-tools-introduction">
                                        <h2>Or you can select more than one option:</h2>
                                        <button
                                            type="submit"
                                            id="results-btn"
                                            disabled={selectedItems.length === 0}
                                            onClick={(event) => { event.preventDefault(); setCurrentPage(1); showToolResults(); }}
                                        >
                                            GO TO RESULTS PAGE →
                                        </button>
                                    </div>
                                    <div className="services-tabs-columns-container">
                                        <div className="services-tabs-column">
                                            <h3>I work in</h3>
                                            <div className="services-tabs-column-works">
                                                {
                                                    potentialUsers.map((item, index) => {
                                                        return (
                                                            <div key={index} className={`services-tabs-tools-card-1 ${selectedItems.includes(item.id) ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem(item.id); }}>
                                                                <h4>{item.name}</h4>
                                                                <button type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="top">
                                                                    <img src={InfoIcon} alt="info_icon" />
                                                                </button>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="services-tabs-column">
                                            <h3>Areas I am interested in</h3>
                                            <div className="services-tabs-column-interests">
                                                <div className="services-tabs-column-interests-1">
                                                    {
                                                        keywords.map((item, index) => {
                                                            if (index % 2 === 0) {
                                                                return (
                                                                    <div key={index} className={`services-tabs-tools-card-2 ${selectedItems.includes(item.id) ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem(item.id); }}>
                                                                        <h4>{item.name}</h4>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className="services-tabs-column-interests-2">
                                                    {
                                                        keywords.map((item, index) => {
                                                            if (index % 2 === 1) {
                                                                return (
                                                                    <div key={index} className={`services-tabs-tools-card-2 ${selectedItems.includes(item.id) ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem(item.id); }}>
                                                                        <h4>{item.name}</h4>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="services-tabs-column">
                                            <h3>I search for</h3>
                                            <div className="services-tabs-column-works">
                                                {
                                                    resourceTypes.map((item, index) => {
                                                        return (
                                                            <div key={index} className={`services-tabs-tools-card-1 ${selectedItems.includes(item.id) ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem(item.id); }}>
                                                                <h4>{item.name}</h4>
                                                                <button type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Find tools for the implementation of your ORRI project">
                                                                    <img src={InfoIcon} alt="info_icon" />
                                                                </button>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {location.pathname === "/oss-platform/services/tools" && showResults && selectedResult === null && (
                                <div className="services-tabs-body-data">
                                    <div className="breadcrumb">
                                        Tools / <span>Result Page</span>
                                    </div>
                                    <div className="back" onClick={(event) => { event.preventDefault(); reset2(); setSearchText(''); setShowResults(false); }}>
                                        <FaArrowLeft />Back to TOOLS
                                    </div>
                                    <div className="tools-title">
                                        Results
                                    </div>
                                    <div className="tools-selections">
                                        {
                                            selectedItems.map((item, index) => (
                                                <div key={index} className="tools-selections-element">
                                                    {
                                                        potentialUsers.find(potentialUser => potentialUser.id === item) && <span>{potentialUsers.find(potentialUser => potentialUser.id === item).name}</span>
                                                    }
                                                    {
                                                        keywords.find(keyword => keyword.id === item) && <span>{keywords.find(keyword => keyword.id === item).name}</span>
                                                    }
                                                    {
                                                        resourceTypes.find(resourceType => resourceType.id === item) && <span>{resourceTypes.find(resourceType => resourceType.id === item).name}</span>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="tools-results">
                                        {
                                            results.map((result, index) => (
                                                <div key={index} className={`tools-results-card ${result.image ? 'tools-results-card-image' : 'tools-results-card-no-image'}`}>
                                                    {result.image && (
                                                        <div className="tools-results-card-image">
                                                            <img src={`data:image/png;base64,${result.image}`} alt={`tool${index}`} />
                                                        </div>
                                                    )}
                                                    <div className="tools-results-card-title">
                                                        {result.title}
                                                    </div>
                                                    <div className="tools-results-card-text">
                                                        {result.summary}
                                                    </div>
                                                    <a href="/#" onClick={(event) => { event.preventDefault(); selectResult(result.id); }}></a>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="row mt-2">
                                        {
                                            totalPages > 1 &&
                                            <div className="col">
                                                <ReactPaginate
                                                    previousLabel={"<"}
                                                    nextLabel={">"}
                                                    pageCount={totalPages}
                                                    onPageChange={({ selected }) => { showToolResults(selected + 1, searchText) }}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"page-link previous"}
                                                    nextLinkClassName={"page-link next"}
                                                    disabledClassName={"pagination__link--disabled"}
                                                    activeClassName={"active"}
                                                    breakClassName={'page-item break2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    currentPage={currentPage - 1}>
                                                </ReactPaginate>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                            {location.pathname === "/oss-platform/services/tools" && showResults && selectedResult !== null && (
                                <div className="services-tabs-body-data">
                                    <div className="breadcrumb">
                                        Tools / Result Page / <span>{selectedResult.title}</span>
                                    </div>
                                    <div className="back" onClick={(event) => { event.preventDefault(); setSelectedResult(null); }}>
                                        <FaArrowLeft />Back to results page
                                    </div>
                                    <div className="tool-container">
                                        <div className="left">
                                            <div className="tool-title">
                                                {selectedResult.title}
                                            </div>
                                            {
                                                selectedResult.image && <div className="tool-image">
                                                    <img src={`data:image/png;base64,${selectedResult.image}`} alt={selectedResult.title} />
                                                </div>
                                            }
                                            <div className="tool-summary">
                                                {selectedResult.summary}
                                            </div>
                                            <div className="tool-results">
                                                <div className="title">
                                                    Publish information
                                                </div>
                                                <p><span>Author:</span> {selectedResult.authors}</p>
                                                <p><span>Year of publication:</span> {selectedResult.yearOfPublication}</p>
                                                <p><span>DOI:</span> <a href={selectedResult.url} target="_blank" rel="noreferrer">{selectedResult.url}</a></p>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="tool-language">
                                                <div className="title">
                                                    Language
                                                </div>
                                                <p>
                                                    {
                                                        selectedResult.resourceTypes.map((keyword, index) => (
                                                            <span key={index}>{keyword}</span>
                                                        ))
                                                    }
                                                    {
                                                        selectedResult.resourcePotentialUsers.map((keyword, index) => (
                                                            <span key={index}>{keyword}</span>
                                                        ))
                                                    }
                                                </p>
                                            </div>
                                            <div className="tool-keywords">
                                                <div className="title">
                                                    Keywords
                                                </div>
                                                <p>
                                                    {
                                                        selectedResult.keywords.map((keyword, index) => (
                                                            <span key={index}>{keyword}</span>
                                                        ))
                                                    }
                                                </p>
                                            </div>
                                            <div className="tool-funded">
                                                {
                                                    selectedResult.funded && (
                                                        <img src={funded} alt="funded" />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {location.pathname === "/oss-platform/services/training" && (
                                <div className="services-tabs-body-data">
                                    <div className="services-tabs-body-data">
                                        <div className="services-tabs-body-data-cards" id="services-tabs-body-data-cards-3">
                                            <div className="services-tabs-cards-info">
                                                <div className="services-tabs-card disabled">
                                                    <a href="/orri-map" id="open-call-link">Crash Courses</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {location.pathname === "/oss-platform/services/pathways" && (
                                <div className="services-tabs-body-data">

                                </div>
                            )}
                            {location.pathname === "/oss-platform/services/tools/own-tool" && (
                                <div className="services-tabs-body-data">
                                    <div className="title">Want to add your own ORRI tool to the REINFORCING OSS?</div>
                                    <div className="subtitle">…maybe this short guide can help you.</div>
                                    <p>
                                        As you may know, REINFORCING aims to be the one-stop source (OSS) to knowledge and expertise to support you on your Open and Responsible Research and Innovation (ORRI) journey. In the REINFORCING OSS (ORRI platform) you can find a wealth of useful resources to support you in designing your ORRI practice with concrete tools, success stories and experiences. All services are easily accessible and tailored to community needs and are structured around particular end-users, areas and categories.
                                    </p>
                                    <p>
                                        In addition, you can upload your own resources to the REINFORCING OSS (<a href="https://reinforcing.eu/oss-platform" style={{ color: "#0f767e" }}>https://reinforcing.eu/oss-platform</a>). To this end, you can follow this <a href="/AYOT_V3.pdf" download style={{ color: "#0f767e" }}>brief guide</a> to facilitate this process. We hope that this guide can help you. After going through this guide, if you have any questions or doubts, you can always use the info@reinforcing.eu email address to contact us.
                                    </p>
                                    <Form noValidate onSubmit={handleSubmit(onSubmit)} id="new-tool-form">

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="resource_name">
                                                <strong>Name of the resource</strong>
                                            </label>
                                            <input
                                                type="text"
                                                {...register("resource_name")}
                                                onInput={handleWordTitleLimit}
                                                className={`form-control mt-1 ${errors.resource_name ? 'is-invalid' : ''}`}
                                                id="resource_name"
                                            />
                                            {wordLimitReached50 && (
                                                <div className="alert alert-warning d-flex align-items-center mt-2" role="alert">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                    </svg>
                                                    <div>
                                                        Has alcanzado el límite de 50 palabras.
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="resource_authors">
                                                <strong>Name of the authors</strong>
                                            </label>
                                            <input
                                                type="text"
                                                {...register("resource_authors")}
                                                onInput={handleWordTitleLimitAuthors}
                                                className={`form-control mt-1 ${errors.resource_authors ? 'is-invalid' : ''}`}
                                                id="resource_authors"
                                            />
                                            {wordLimitReached50Authors && (
                                                <div className="alert alert-warning d-flex align-items-center mt-2" role="alert">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                    </svg>
                                                    <div>
                                                        Has alcanzado el límite de 50 palabras.
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="summary">
                                                <strong>Summary</strong>
                                            </label>
                                            <textarea
                                                rows={14}
                                                {...register("summary")}
                                                onInput={handleWordSummaryLimit}
                                                className={`form-control mt-1 ${errors.summary ? 'is-invalid' : ''}`}
                                                id="summary"
                                                style={{ minHeight: '100px', maxHeight: '300px', resize: 'vertical', overflowY: 'auto' }}
                                            ></textarea>
                                            {wordLimitReached300 && (
                                                <div className="alert alert-warning d-flex align-items-center mt-2" role="alert">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                    </svg>
                                                    <div>
                                                        Has alcanzado el límite de 300 palabras.
                                                    </div>
                                                </div>
                                            )}
                                            {errors.summary && (
                                                <p className="error-message">{errors.summary.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="publication_year"><strong>Year of publication</strong></label>
                                            <select
                                                {...register("publication_year", { required: 'Year of publication is required' })}
                                                className={`form-control mt-1 ${errors.publication_year ? 'is-invalid' : ''}`}
                                                id="publication_year"
                                            >
                                                <option value="">Select a year of publication</option>
                                                {Array.from(new Array(new Date().getFullYear() - 1699), (val, index) => 1700 + index).reverse().map(year => (
                                                    <option key={year} value={year}>{year}</option>
                                                ))}
                                            </select>
                                            {errors.resource_type && (
                                                <p className="error-message">{errors.publication_year.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="image"><strong>Image</strong></label>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                className={`form-control mt-1 ${errors.image ? 'is-invalid' : ''}`}
                                                id="image"
                                                {...register("image")}
                                                onChange={handleImageChange}
                                            />
                                            {selectedImage && (
                                                <div className="mt-2">
                                                    <img
                                                        src={URL.createObjectURL(selectedImage)}
                                                        alt="Selected"
                                                        style={{ maxWidth: '100px' }}
                                                    />
                                                    <button
                                                        type="button"
                                                        id="remove-image-btn"
                                                        className="btn btn-danger mt-2"
                                                        onClick={handleRemoveImage}
                                                    >
                                                        Remove Image
                                                    </button>
                                                </div>
                                            )}
                                            {errors.image && (
                                                <p className="error-message">{errors.image.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="link"><strong>Link</strong> (URL)</label>
                                            <input
                                                type="url"
                                                {...register("link", { required: 'Link is required' })}
                                                className={`form-control mt-1 ${errors.link ? 'is-invalid' : ''}`}
                                                id="link"
                                            />
                                            {errors.link && (
                                                <p className="error-message">{errors.link.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="resource_type"><strong>Type of resource</strong> (select one)</label>
                                            <select
                                                {...register("resource_type", { required: 'Resource type is required' })}
                                                className={`form-control mt-1 ${errors.resource_type ? 'is-invalid' : ''}`}
                                                id="resource_type"
                                            >
                                                <option value="">Select a resource type</option>
                                                {resourceTypes.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.resource_type && (
                                                <p className="error-message">{errors.resource_type.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="keywords"><strong>Keywords</strong> (you can select several fields)</label>
                                            {keywords.map((item) => (
                                                <div key={item.id} className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={item.id}
                                                        id={item.id}
                                                        {...register("keywords")}
                                                    />
                                                    <label className="form-check-label" htmlFor={item.id}>
                                                        {item.name}
                                                    </label>
                                                </div>
                                            ))}
                                            {errors.keywords && (
                                                <p className="error-message">{errors.keywords.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="potential_users"><strong>Potential user of the resource</strong> (select several, but try to avoid as much as possible the use of the “Other”)</label>
                                            {potentialUsers.map((item) => (
                                                <div key={item.id} className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={item.id}
                                                        id={item.id}
                                                        {...register("potential_users")}
                                                    />
                                                    <label className="form-check-label" htmlFor={item.id}>
                                                        {item.name}
                                                    </label>
                                                </div>
                                            ))}
                                            {errors.potential_users && (
                                                <p className="error-message">{errors.potential_users.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="btn btn-primary mt-5 register"
                                            form="new-tool-form"
                                            id="form-btn"
                                        >
                                            <span><strong>Submit</strong></span>
                                        </Button>

                                    </Form>

                                </div>
                            )}
                        </div>
                        {location.pathname === "/oss-platform/services/grants" && (
                            <div className="services-tabs-body-links-container">
                                <div className="services-tabs-body-links">
                                    <div className="services-tabs-link-card">
                                        <h1>Interested in match-making?</h1>
                                        <a href="/orri-map" id="orri-map-link">Find ORRI practitioners across Europe →</a>
                                    </div>
                                    <div className="services-tabs-link-card">
                                        <h1>Interested in building capacity?</h1>
                                        <a href="/#" id="building-link">Find ORRI training resources →</a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Newsletter />
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}