import { ToastContainer, toast } from 'react-toastify';
import sendEmail from "../services/emailService.js";
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';

import "./Newsletter.css";

export default function ContactUs() {

    const handleSubmit = (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        
        sendEmail({ email })
        .then(result => {
            if (result === 0) {
                toast.success('Thank you for subscribing to our newsletter!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            } else if (result === 1) {
                toast.warn('The entered email address is already subscribed to our newsletter.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
                // toast.error('An error has occurred when subscribing to our newsletter. Please try again later.', {
                //     position: "top-center",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light"
                // });
            }
        })
        .catch(error => {
            console.error('There was a problem with the promise:', error);
        });

    };

    return (
        <div id="registration-banner">
            <div className="content">
                <div className="registration-title">
                    Register for our
                </div>
                <div className="registration-subtext">
                    quarterly newsletter!
                </div>
                <form id="subscribe-form" onSubmit={handleSubmit}>
                    <input type="email" id="form-control" name="email" placeholder="Enter your e-mail" required />
                    <button type="submit" id="subscribe-btn">
                        SUSCRIBE
                    </button>
                </form>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );

}