function sendEmail(data) {

    const apiUrl = process.env.REACT_APP_API_URL;

    const userEmail = {
        email: data.email
    };

    return fetch(`${apiUrl}/api/users/subscribe`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userEmail)
    })
    .then(response => {
        if (response.ok) {
            return 0;
        } else {
            return 1;
        }
        
    })
    .catch(error => {        
        console.error('There was an error!', error);
        return 1;
    });

}

export default sendEmail;